import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {AngularFireModule} from "@angular/fire";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFireFunctionsModule, REGION} from "@angular/fire/functions";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {AngularFireStorageModule} from "@angular/fire/storage";

import {NgxSpinnerModule} from "ngx-spinner";
import {environment} from "../environments/environment";
import {ToolbarModule} from "primeng/toolbar";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import {CertbyrutModule} from "./plataforma/utilities/certbyrut/certbyrut.module";

import {AuthInterceptor} from "./auth.interceptor";
import {DialogService, DynamicDialogModule} from "primeng/dynamicdialog";
import {ConfirmationService, MessageService} from "primeng/api";
import {ReactiveFormsModule} from "@angular/forms";
import {ToastModule} from "primeng/toast";

@NgModule({
	declarations: [AppComponent],
	imports: [
		DynamicDialogModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		ToolbarModule,
		AngularFireAuthModule,
		AngularFireFunctionsModule,
		AngularFirestoreModule,
		AngularFireStorageModule,
		NgxSpinnerModule,
		HttpClientModule,
		CertbyrutModule,
		HttpClientModule,
		ReactiveFormsModule,
		ToastModule
	],
	providers: [
		MessageService,
		DialogService,
		ConfirmationService,
		{provide: REGION, useValue: "southamerica-east1"},
		// {provide:RouteReuseStrategy, useValue:CustomReuseStrategy}
		{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
	],

	bootstrap: [AppComponent]
})
export class AppModule {}

