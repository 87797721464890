import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../servicios/auth.service';

@Injectable({
  providedIn: 'root'
})
  /** Guard para permitir el acceso solo a personas que este autenticadas en el sistema */
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private _auth:AuthService,private _router:Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  async canLoad(
    route: Route,
    segments: UrlSegment[]):  Promise<boolean> {

    const user = await this._auth.isLogged();
    //console.log(user);
    if (!user) {
      this._router.navigate(['/login']);
      return false;
    }


    return true;


  }
}
