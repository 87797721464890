import {Component} from "@angular/core";
import {NgxSpinnerService} from "ngx-spinner";
import {PrimeNGConfig} from "primeng/api";
import {environment} from "src/environments/environment";
import {es} from "./plataforma/locales";
import {UserService} from "./plataforma/servicios/user.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent {
	title = "capacitacion";
	constructor(private _user: UserService, private _Pconfig: PrimeNGConfig, private _loading: NgxSpinnerService) {
		this.inicializarApp();
	}

	/** @description Metodo para inicializar todo cuando la app cargue */
	async inicializarApp() {
		this.removeConsoleOnPRod();
		this._loading.show("benevo");
		await this._user.initUser();
		this._loading.hide("benevo");
		this.primengApiSet();
		/** Prime ng api */
	}
	/** @description cambiamos las cosas en el prime ng api */
	primengApiSet() {
		this._Pconfig.setTranslation(es);
	}
	/** @description Removemos las consolas cuando estamos en produccion */
	removeConsoleOnPRod() {
		if (window) {
			// console.log("Window existe");
			if (environment.production) {
				window.console.log =
					window.console.error =
					window.console.info =
					window.console.debug =
					window.console.warn =
					window.console.trace =
					window.console.dir =
					window.console.dirxml =
					window.console.group =
					window.console.groupEnd =
					window.console.time =
					window.console.timeEnd =
					window.console.assert =
					window.console.profile =
						function () {};
				console.log("Removido");
			}
		}
	}
}

