import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CertbyrutRoutingModule } from './certbyrut-routing.module';
import { CertbyrutComponent } from './certbyrut.component';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [CertbyrutComponent],
  imports: [
    CommonModule,
    CertbyrutRoutingModule,ButtonModule,TableModule,TooltipModule,DropdownModule,FormsModule,
  ],exports:[CertbyrutComponent],
})
export class CertbyrutModule { }
