<div *ngIf="modo === 2 && usersByName.length > 1" class="col-12 text-center">
<h6>Hemos encontrado varios usuarios con el nombre de {{name}}. <br> Por favor eliga uno</h6>
  <p-dropdown  placeholder="Seleccione el usuario"  [styleClass]="'w-100'"

  [optionLabel]="'firstname'" [appendTo]="'body'" [baseZIndex]="6000"  #dp
  (onChange)="rut = dp.value.RUT; getDataByRut(rut)"
  [options]="usersByName">
    <ng-template pTemplate="selectedItem">
      <div class="country-item country-item-value"  *ngIf="dp.value">

          <div>{{dp.value?.firstname}} {{dp.value?.lastname}}</div>
      </div>
  </ng-template>
  <ng-template let-c pTemplate="item">
  <div>
    {{c?.firstname}} {{c?.lastname}}
  </div>
  </ng-template>
  </p-dropdown>
</div>


<div *ngIf="rut">
  <p-table [styleClass]="'cert-table'" [responsive]="true"  [paginator]="true" [alwaysShowPaginator]="false" [rows]="5"  [value]="certificados">
    <ng-template pTemplate="caption">
      <h4 *ngIf="certificados && certificados.length > 0">
       Certificados vigentes de {{certificados[0]?.user?.name}}
      </h4>
    </ng-template>
      <ng-template pTemplate="header">
        <tr>
        <th>
          Nombre
        </th>
        <th>
          Capacitación
        </th>
        <th>
          Vigencia
        </th>
        <th>
          Acciones
        </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cert>
        <tr>
          <td>
            <span class="p-column-title">Nombre</span>
            <span>{{cert.user.name}}</span>
          </td>
          <td> <span class="p-column-title">Capacitación</span>
            <span>
              {{cert.capacitacion?.name}}
            </span>
          </td>
          <td>
            <span class="p-column-title">Vigencia</span>
            <span>
              {{moment(cert.vigenciaCert).format('DD-MM-YYYY')}}
            </span>
          </td>
          <td>
            <span class="p-column-title">Acciones</span>
            <button  [tooltipZIndex]="5000" icon="pi pi-download" pTooltip="Descargar certificado" pButton (click)="verCert(cert)" class="p-button-success p-button-rounded p-button-icon p-mr-2">

            </button>
            <button (click)="close()"
            *ngIf="cert?.grupos?.length > 0 && (cert.capacitacion.admParciales.indexOf(_user.user.uid) > -1
            || _user.userClaims.rol === 3 || _user.userClaims.rol === 4) "
            [tooltipZIndex]="5000" pTooltip="Ver curso" icon="pi pi-search" [routerLink]="['/ver-grupo']" [queryParams]="{id:cert.grupos[0]}"
            pButton class="p-button-help p-button-rounded p-button-icon p-mr-2"></button>
          </td>
        </tr>
      </ng-template>
<ng-template pTemplate="emptymessage">
  <div>
    <span>No tiene certificados vigentes</span>
  </div>
</ng-template>
    </p-table>

    <p-table [styleClass]="'cert-table'" *ngIf="certificadosPendientes" [responsive]="true"  [paginator]="true" [alwaysShowPaginator]="false" [rows]="5"  [value]="certificadosPendientes">
      <ng-template pTemplate="caption">
        <h4 *ngIf="certificadosPendientes && certificadosPendientes.length > 0">
         Capacitaciones pendientes de {{certificadosPendientes[0]?.user?.name}}
        </h4>
      </ng-template>
        <ng-template pTemplate="header">
          <tr>
          <th>
            Nombre
          </th>
          <th>
            Capacitación
          </th>
          <th>
            Estado
          </th>
          <th>
            Acciones
          </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cert>
          <tr>
            <td>
              <span class="p-column-title">Nombre</span>
              <span>{{cert.user.name}}</span>
            </td>
            <td> <span class="p-column-title">Capacitación</span>
              <span>
                {{cert.capacitacion?.name}}
              </span>
            </td>
            <td>
              <span class="p-column-title">Estado</span>
              {{cert.status}}
            </td>
            <td>
              <span class="p-column-title">Acciones</span>
              <button (click)="close()"
              *ngIf="cert?.grupos?.length > 0 && (cert.capacitacion.admParciales.indexOf(_user.user.uid) > -1
              || _user.userClaims.rol === 3 || _user.userClaims.rol === 4) "
              [tooltipZIndex]="5000" pTooltip="Ver curso" icon="pi pi-search" [routerLink]="['/ver-grupo']" [queryParams]="{id:cert.grupos[0]}"
              pButton class="p-button-help p-button-rounded p-button-icon"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <div>
            <span>No tiene certificados pendientes</span>
          </div>
        </ng-template>
      </p-table>


</div>
