import {Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/functions';

@Injectable({
	providedIn: 'root'
})
export class VerificadorService {
	constructor(private _aFunctions: AngularFireFunctions) {}
	/**
	 * @description MEtodo para verificar el codigo
	 * @param codigo codigo unico del certificado
	 */
	async verificar(codigo: number) {
		try {
			const handler = this._aFunctions.httpsCallable('verificadorCertificado');
			const examen: any = await handler({codigo: codigo}).toPromise();
			return examen;
		} catch (err) {
			throw err;
		}
	}

	/**
	 * @description Metodo para listar los certificados de un usuario por el rut
	 * ´@param rut rut del usuario a verificar
	 */
	async listarCertsByRut(rut: string) {
		try {
			const handler = this._aFunctions.httpsCallable('searchCertByRut');
			const examenes = handler({rut: rut}).toPromise();
			return examenes;
		} catch (err) {
			throw err;
		}
	}
	/** listar examenes pendiente por el usuario */
	async listarExamenesPendienteByRu(rut: string) {
		try {
			//this._aFunctions.useEmulator('127.0.0.1', 5001);
			const examenes = await this._aFunctions.httpsCallable('getExamenesPendientesByRut')({rut: rut}).toPromise();
			return examenes;
		} catch (err) {
			throw err;
		}
	}

	/**   */
	async listarCertsByNameAndLastname(name: string) {
		try {
			//     this._aFunctions.useEmulator('localhost', 5001);
			const certs = await this._aFunctions.httpsCallable('buscarCertificadosPorNombreYApellido')({name: name}).toPromise();

			return certs;
		} catch (err) {
			throw err;
		}
	}
}

