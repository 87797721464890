import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {AuthService} from "src/app/servicios/auth.service";
import {user} from "../../../../interfaces";
import {Subscription} from "rxjs";
import {AngularFireFunctions} from "@angular/fire/functions";
import {HttpClient} from "@angular/common/http";
import {environment} from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class UserService {
	/** Usuario */
	user: user = null;
	/** User Claims */
	userClaims: any = null;
	/** user subscription */
	user$: Subscription;
	/**Token del usuario */
	userToken!: string;

	URL = environment.url_prod;

	/** Clase para manejar al usuario que se conecta  a la app */
	constructor(private http: HttpClient, private _auth: AuthService, private _aFirestore: AngularFirestore, private _aFunctions: AngularFireFunctions) {}
	/** @description Metodo para encontrar al usuario que esta usando la app */
	async initUser() {
		try {
			const user = await this._auth.isLogged();

			if (!user) {
				console.log("Usuario no encontrado");
				return;
			}
			this.userToken = await user.getIdToken(true);
			/** Encontramos los claims de este usuario */
			this.userClaims = await (await user.getIdTokenResult(true))?.claims;
			/** Encontramos al usuario en la DB */
			const res: any = await this.http.post(`${this.URL}/users/obtener-por-id`, {uid: user.uid}).toPromise();
			this.user = res.result;

			/* this.user$ =  this._aFirestore.collection<user>('users').doc(user.uid).valueChanges().subscribe((us) => {
  console.log("Seteamos al usuario");
  this.user = us;
})*/
			return;
		} catch (err) {
			console.log(err);
		}
	}
	/** Metodo que se aplica cuando el usuario se va a loguear */
	onLogout() {
		/** Removemos la subscription */
		if (this.user$) {
			this.user$.unsubscribe();
			console.log("Subscripcion del usuario removida");
		}
		/** Removemos los claims */
		if (this.userClaims) {
			this.userClaims = null;
			console.log("Claims removidas");
		}
	}

	getRol() {
		return this.user?.rol;
	}

	getRole() {
		return this.user.rol;
	}
}

