import {NgModule} from "@angular/core";
import {Routes, RouterModule, PreloadAllModules, NoPreloading} from "@angular/router";
import {AuthGuard} from "./guards/auth.guard";
import {NoLoginGuard} from "./guards/no-login.guard";

const routes: Routes = [
	{path: "verificador", loadChildren: () => import("./verificador/verificador.module").then((m) => m.VerificadorModule)},
	{path: "check", loadChildren: () => import("./listar-certs/listar-certs.module").then((m) => m.ListarCertsModule)},

	{
		path: "login",
		loadChildren: () => import("./login/login.module").then((m) => m.LoginModule),
		canActivate: [NoLoginGuard]
	},

	{path: "", loadChildren: () => import("./plataforma/plataforma.module").then((m) => m.PlataformaModule), canLoad: [AuthGuard]},
	{path: "reglamento", loadChildren: () => import("./plataforma/utilities/reglamento/reglamento.module").then((m) => m.ReglamentoModule)}

	/** Redirigmos*/
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}

