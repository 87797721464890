import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {examen, user} from '../../../../../interfaces';
import {VerificadorService} from 'src/app/servicios/verificador.service';
import {UserService} from '../../servicios/user.service';
import * as moment from 'moment';
import {PdfServiceService} from '../../servicios/pdf-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
@Component({
	selector: 'app-certbyrut',
	templateUrl: './certbyrut.component.html',
	styleUrls: ['./certbyrut.component.scss']
})
export class CertbyrutComponent implements OnInit {
	rut: string;
	/** Moment */
	moment = moment;
	/** Certificados */
	certificados: examen[];
	/** cerficiados pendientes */
	certificadosPendientes: examen[];
	/** Modo de busqueda  */
	modo: number;
	/** name */
	name: string;
	/** lastname */
	lastname: string;
	/** Users que encontramos por nombre */
	usersByName: user[] = [];
	/** Selected user */
	selectedUser: any;
	constructor(
		private _veri: VerificadorService,
		private _pdf: PdfServiceService,
		private _loading: NgxSpinnerService,
		private _config: DynamicDialogConfig,
		private _ref: DynamicDialogRef,
		private _router: Router,
		private _route: ActivatedRoute,
		public _user: UserService
	) {}

	ngOnInit(): void {
		this.modo = this._config.data?.modo;
		if (this.modo === 1) {
			this.rut = this._config.data?.rut;
		} else if (this.modo === 2) {
			this.name = this._config.data.name;
			this.lastname = this._config.data.lastname;
		}

		this.getData();
	}

	async getDataByRut(rut: string) {
		try {
			this._loading.show();
			const datos = await Promise.all([this._veri.listarCertsByRut(rut), this._veri.listarExamenesPendienteByRu(rut)]);
			this.certificados = datos[0];
			this.certificadosPendientes = datos[1];
			this._loading.hide();
		} catch (err) {
			console.log(err);
			this._loading.hide();
			if (err.code === 'unknown') {
				alert(err.message);
			} else {
				alert('Ocurrio un error inesperado');
			}
		}
	}

	async getData() {
		try {
			this._loading.show();

			if (this.modo === 1) {
				this.certificados = await this._veri.listarCertsByRut(this.rut);
				this.certificadosPendientes = await this._veri.listarExamenesPendienteByRu(this.rut);
			} else if (this.modo === 2) {
				this.usersByName = await this._veri.listarCertsByNameAndLastname(this.name);
				console.log(this.usersByName);
				if (this.usersByName.length === 1) {
					this.rut = this.usersByName[0].RUT;
					this.getDataByRut(this.usersByName[0].RUT);
				}
				// this.certificadosPendientes = await this._veri.listarExamenesPendienteByRu(this.rut);
			}

			this._loading.hide();
			console.log(this.certificados);
			console.log(this.certificadosPendientes);
		} catch (err) {
			console.log(err);
			if (err.code === 'unknown') {
				alert(err.message);
			} else {
				alert('Ocurrio un error inesperado');
			}
			this._loading.hide();
			this._ref.close();
		}
	}
	/** Ver certifciado */
	async verCert(cert: examen) {
		await this._pdf.generateCertificado(cert, false);
	}
	/** Close */
	close() {
		this._ref.close();
	}
}

