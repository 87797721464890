export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBX3DKrSyAAm_JG6ezfRZtWzWB6jq_76WI",
    authDomain: "capacitacion-578d3.firebaseapp.com",
    projectId: "capacitacion-578d3",
    storageBucket: "capacitacion-578d3.appspot.com",
    messagingSenderId: "539508170170",
    appId: "1:539508170170:web:0bb00ee320858303d49183",
    measurementId: "G-DVF27H5R0M"
  },
  catIdParaDas:'6WyQgkNxu70GQPcMYmsR',
  url_debug:'http://localhost:5001/capacitacion-578d3/southamerica-east1/api',
  url_prod:'https://southamerica-east1-capacitacion-578d3.cloudfunctions.net/api',
};
