import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import {first} from 'rxjs/operators'
@Injectable({
  providedIn: 'root'
})
/** Servicio de autenticacion por firebase */
export class AuthService {

  constructor(private _aFauth: AngularFireAuth, private _router: Router, private _aFirestore: AngularFirestore,
    private _aFunctions: AngularFireFunctions,) { }

  /** @description metodo para acceder a la plataforma
   * @param correo Correo electronico para acceder
   * @param contraseña Contraseña
   */
  async acceder(correo, contraseña) {
    try {
      const user = await this._aFauth.signInWithEmailAndPassword(correo, contraseña);
      return user;
    }
    catch (err) {
      throw (err);
    }
  }
/** @description Metodo para obtener el usuario logueado en la APP
 * @returns Devuelve una promesa que al resolverse retorna el usuario o null
 */
  async isLogged() {
    return await this._aFauth.authState.pipe(first()).toPromise();
  }
  /** @description Meotod para salir de la app
   *
   */
  async logout() {
    try {
      await this._aFauth.signOut();
      window.location.href = 'login';
    //  this._router.navigate(['/login']);
      return;
    }
    catch (err) {
      throw (err);
    }
  }

/** Metodo para resetear tu contraseña
 * @param email correo electronico a resetear password
*/
 async resetPassword(email:string) {
   try {

   await  this._aFauth.sendPasswordResetEmail(email, { url: 'https://beneoaulavirtual.cl/login' });
   }
   catch (err) {
     throw (err);
   }
 }

/** Metodo para cambiar la contraseña
 * @param newPasword nueva contraseña
 */
  async cambiarContraseña(newPasword:string) {
    try {
      const user = await this.isLogged();
      if (!user) throw ("No se consiguio al usuario");
      await user.updatePassword(newPasword);
    //  await this._aFirestore.collection('users').doc(user.uid).update({ password: newPasword });
      await this._aFunctions.httpsCallable('UpdatePassword')({ uid: user.uid, newPassword: newPasword });
      return;
    }
    catch (err) {
      throw (err);
    }
  }

  /** Cambiamos el rol de un usuario demo
   * @param uid id d eusuario
   * @param rol rol
  */
 async changeRolForDemoUser(uid: string, rol: number) {
   try {
     const handler = this._aFunctions.httpsCallable('updateRolDemo');
     await handler({ uid: uid, rol: rol }).toPromise();
     return;
   }
   catch (err) {
     throw (err);
   }
  }


}
